
class GrapeUIPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;

		Grape.UsersPlugin = this;
	}

	async onInit()
	{
		this.Grape.currentSession = null;
		this.Grape.currentSessionObservable = ko.observable(null);

		let data = await Grape.fetches.getJSON('/api/session/info', {}, {cache: 'no-cache'});
		if (data.status == 'OK')
		{
			this.Grape.config.public_settings = data.public_settings || {};
			this.setSession(data.session, null);
		}
	}

	async setSession(session, trigger_hook='onSessionChange')
	{
		if (session == null)
		{
			Grape.currentSession = null;
			localStorage.removeItem('session_id');
			localStorage.removeItem('roles');
			localStorage.removeItem('username');
		}
		else
		{
			Grape.currentSession = JSON.parse(JSON.stringify(session));
			localStorage.setItem('session_id', session.session_id);
			if (session.roles)
				localStorage.setItem('roles', session.roles);
		}

		await this.injectPublishedAssets();

		this.Grape.currentSessionObservable(session);
		if (trigger_hook)
			await Grape.plugins.triggerPluginHooks(trigger_hook, [Grape.currentSession]);
	}

	async injectPublishedAssets()
	{
		let list;
		try {
			list = await Grape.fetches.getJSON('/api/server/asset-list');
		} catch (err) {
			console.warn('GET /api/server/asset-list failed', err);
			return;
		}

		const p = [];
		for (const i of list)
		{
			if (i.type == 'js')
			{
				p.push(new Promise((resolve, reject) => {
					const script = document.createElement('script');
					script.src = i.url;
					script.type = 'module';
					script.onload = resolve;
					script.onerror = reject;
					document.head.appendChild(script);
				}));
			}
			else if (i.type == 'css')
			{
				p.push(new Promise((resolve, reject) => {
					const e = document.createElement('link');
					e.rel = 'stylesheet';
					e.href = i.url;
					e.onload = resolve;
					e.onerror = reject;
					document.head.appendChild(e);
				}));
			}
		}
		return Promise.all(p);
	}
}

export default GrapeUIPlugin;

